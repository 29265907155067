import { createIntl, createIntlCache, IntlShape } from '@formatjs/intl';
import moment from 'moment';
import type { Plugin } from 'vue';
import { intlKey } from 'vue-intl';

const cache = createIntlCache();

type ModulePathOrLangKeys = string | Record<string, string>;

export class EBIntl {
  lastIntl: IntlShape<string>;
  languages: string[];

  constructor(private languagesModuleMap: Record<string, ModulePathOrLangKeys>) {
    this.lastIntl = createIntl({
      locale: 'en',
      onError: () => {
        // Just want to ignore message about missing lang text
      }
    });
    this.languages = Object.keys(languagesModuleMap);
  }

  createIntlWithFixedLangKeys(
    additionLangKeys?: Record<string, Record<string, string>>,
    langKeys?: Record<string, string>
  ): IntlShape<string> {
    const selectedLanguage = this.selectedAppLanguage;

    // to handle custom language like "de_vkb" => "de" only (all custom languages have to follow "{intl locale}_{custom name}")
    const intlLocale = selectedLanguage.includes('_')
      ? selectedLanguage.split('_')[0]
      : selectedLanguage;

    try {
      this.lastIntl = createIntl(
        {
          locale: intlLocale,
          messages: {
            // This "additionLangKeys?.[intlLocale]" would help to custom lang like "de_vkb" can keep using "de" from engine
            ...(additionLangKeys?.[selectedLanguage] ?? additionLangKeys?.[intlLocale] ?? {}),
            ...langKeys
          },
          onError: () => {
            // Just want to ignore message about missing lang text
          }
        },
        cache
      );
    } catch (error) {
      console.error(`${intlLocale}/${selectedLanguage} is not supported by Intl`);
    }

    return this.lastIntl;
  }

  async createIntl(additionLangKeys?: Record<string, Record<string, string>>): Promise<Plugin> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let intlShape: any;
    const modulePathOrLangKeys = this.languagesModuleMap[this.selectedAppLanguage];

    if (typeof modulePathOrLangKeys === 'string') {
      intlShape = await import(
        /* @vite-ignore */ `${location.origin}/${modulePathOrLangKeys}`
      ).then((langKeys) => {
        return this.createIntlWithFixedLangKeys(additionLangKeys, langKeys.default);
      });
    } else {
      intlShape = this.createIntlWithFixedLangKeys(additionLangKeys, modulePathOrLangKeys);
    }

    return {
      install: (app) => {
        // sync from ebuilder-js-sdk/node_modules/vue-intl/lib_esnext/plugin.js
        app.config.globalProperties.$intl = intlShape;
        app.config.globalProperties.$formatMessage = intlShape.formatMessage;
        app.config.globalProperties.$formatDate = intlShape.formatDate;
        app.config.globalProperties.$formatTime = intlShape.formatTime;
        app.config.globalProperties.$formatDateTimeRange = intlShape.formatDateTimeRange;
        app.config.globalProperties.$formatRelativeTime = intlShape.formatRelativeTime;
        app.config.globalProperties.$formatDisplayName = intlShape.formatDisplayName;
        app.config.globalProperties.$formatNumber = intlShape.formatNumber;
        app.config.globalProperties.$formatList = intlShape.formatList;
        app.provide(intlKey, intlShape);
      }
    };
  }

  public get supportedLangs(): string[] {
    return this.languages;
  }

  /**
   * This is "locale" of current Intl, which is lang code supported by Intl (en, de, it, fr....),
   * not the custom language code (like de_vkb -> de)
   */
  public get selectedLang(): string {
    return this.lastIntl.locale.toLowerCase();
  }

  /**
   * This is the selected language code included custom langs (like de_vkb -> de_vkb)
   * if user havent's selected
   */
  public get selectedAppLanguage(): string {
    const selectedLanguage = localStorage.getItem('__EB_lang__') ?? '';

    if (selectedLanguage && this.languages.includes(selectedLanguage)) {
      return selectedLanguage;
    }

    let supportedPreferredLangs: string[] = [];

    if (window.navigator.languages && window.navigator.languages.length > 0) {
      supportedPreferredLangs.push(...window.navigator.languages);
    }

    if (window.navigator.language) {
      supportedPreferredLangs.push(window.navigator.language);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((<any>window.navigator).userLanguage) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      supportedPreferredLangs.push((<any>window.navigator).userLanguage);
    }

    supportedPreferredLangs = supportedPreferredLangs.map((x) => x.split('-')[0]);

    if (supportedPreferredLangs.length) {
      for (const supportedPreferredLang of supportedPreferredLangs) {
        const matchedLang = this.languages
          .sort()
          .find((x) => x.split('_')[0] === supportedPreferredLang);

        if (matchedLang) {
          return matchedLang;
        }
      }
    }

    return 'en';
  }

  changeLanguage(langCode: string): void {
    localStorage.setItem('__EB_lang__', langCode);

    window.location.reload();
  }

  formatMessage(id: string, values?: Record<string, unknown>): string {
    return this.lastIntl.formatMessage({ id }, values);
  }

  formatDate(value: string, customFormat?: string): string {
    return moment(value).format(customFormat || this.formatMessage('__eb_date_format'));
  }

  formatDateTime(value: string, customFormat?: string): string {
    return moment(value).format(customFormat || this.formatMessage('__eb_datetime_format'));
  }

  formatTime(value: string, customFormat?: string): string {
    return moment(value).format(customFormat || this.formatMessage('__eb_time_format'));
  }

  formatNumber(value: number, options?: { fraction: number }): string {
    const { fraction } = options ?? {};
    const maximumFractionDigits = typeof fraction === 'number' ? fraction : 2;
    const minimumFractionDigits = typeof fraction === 'number' ? fraction : 0;

    return this.lastIntl.formatNumber(Number(value ?? 0), {
      maximumFractionDigits,
      minimumFractionDigits
    });
  }
}
